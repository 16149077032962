<template>
  <div class="test-page">
    <div ref="sand" id="sand">
      <img id="sand-img" :src="require('../../../../../assets/img/background.jpg')"/>
      <div draggable="true" class="knob" :id="'dragknob' + index" v-for="(item, index) in pointList" :key="index" :style="'position: absolute; left:' + item.left + '; top:' + item.top + ';'">
        <img :src="require('../../../../../assets/img/konb.png')"/>
        <div class="center" @mousedown="selectKonb($event, index)"></div>
        <div class="point" @mousedown="rotateKonb($event, index)"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Test',
  data () {
    return {
      pointList: [
        { positionX: 0, positionY: 0 }
      ]
    }
  },
  methods: {
    // 拖拽
    selectKonb (e, index) {
      e.preventDefault && e.preventDefault()
      const odiv = document.getElementById('dragknob' + index)// 获取目标元素
      // 获取画布元素的宽高
      const dragDom = document.getElementById('sand')
      const domW = dragDom.offsetWidth
      const dowH = dragDom.offsetHeight
      // 获取拖动图标的宽高
      const midKonbDomW = odiv.offsetWidth
      const midKonbDomH = odiv.offsetHeight
      // 算出鼠标相对元素的位置
      const disX = e.clientX - odiv.offsetLeft
      const disY = e.clientY - odiv.offsetTop
      window.onmousemove = (e) => {
        // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let left = 0
        let top = 0
        if ((e.clientX - disX) <= 0) {
          left = 0
        } else if ((e.clientX - disX) >= (domW - midKonbDomW)) {
          left = domW - midKonbDomW
        } else {
          left = e.clientX - disX
        }
        if ((e.clientY - disY) <= 0) {
          top = 0
        } else if ((e.clientY - disY) >= (dowH - midKonbDomH)) {
          top = dowH - midKonbDomH
        } else {
          top = e.clientY - disY
        }
        // 绑定元素位置到positionX和positionY上面
        this.pointList[index].positionX = left
        this.pointList[index].positionY = top

        // 移动当前元素
        odiv.style.left = left + 'px'
        odiv.style.top = top + 'px'
        console.log('dowH:' + dowH + 'dowW' + domW)
        window.onmouseup = (e) => {
          window.onmousemove = null
          window.onmouseup = null
        }
      }
    },
    // 旋转
    rotateKonb (e, index) {
      e.preventDefault && e.preventDefault()
      // 获取目标元素
      const odiv = document.getElementById('dragknob' + index)
      // 获取拖动图标的宽高
      const midKonbDomW = odiv.offsetWidth
      const midKonbDomH = odiv.offsetHeight
      // 获取目标元素中心点
      const desdence = odiv.getBoundingClientRect()
      const centerPoint = {
        x: desdence.left + (midKonbDomW / 2),
        y: desdence.top + (midKonbDomH / 2)
      }
      window.onmousemove = (event) => {
        const beginPoint = {
          x: event.clientX,
          y: event.clientY
        }
        let angle = -Math.atan2(centerPoint.x - beginPoint.x, centerPoint.y - beginPoint.y) / Math.PI * 180
        if (angle <= 0) {
          angle = 180 + (180 + angle)
        }
        odiv.style.transform = 'rotate(' + angle + 'deg)'
        console.log(angle)
        window.onmouseup = (e) => {
          window.onmousemove = null
          window.onmouseup = null
        }
      }
    }
  }
}
</script>

<style scoped>
.test-page{
  width: 100%;
  height: 100%;
  position: relative;
}
#sand{
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  height: auto;
}
#sand img{
  width: 100%;
  height: 100%;
}
/deep/.hot-point{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
}
.knob{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  user-select: none;
}
.knob img{
  width: 100%;
  height: 100%;
}
.knob .center{
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: rgba(0,0,0,0.2);
  position: absolute;
  left: calc(50% - 8px);
  top: calc(50% - 8px);
  cursor: grab;
}
.knob .point{
  position: absolute;
  top: 0;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 14px;
  height: 14px;
  cursor: ew-resize;
}
</style>
